import React, { useEffect } from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import ExampleCard from './Presentation/components/ExampleCard';
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import productImage from "assets/product-images/product-image.jpeg";

import footerRoutes from "footer.routes";
import MKButton from 'components/MKButton';
import { Card } from '@mui/material';

const modalProduct = [
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/mbm.jpg",
        name: "Meat Bone Meal",
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/pmm.jpg",
        name: "Poultry Meat Meal",
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/sbm.jpg",
        name: "Soy Bean Meal",
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/energymeal.jpg",
        name: "Energy Meal",
    }
]

const modalProductList = [
    {
        category: "Meat Bone Meal (MBM)",
        identifier: "mbm",
        subtitle: "Tepung Daging Tulang",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/mbm.jpg",
                name: "Meat Bone Meal (MBM)",
                videoURL: "",
                description: {
                    "Crude Protein" : "± 50%",
                    "Fat": "max 15%",
                    "Ash": "max 30%",
                }
            }
        ],
    },
    {
        category: "Poultry Meat Meal (PMM)",
        identifier: "pmm",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/pmm.jpg",
                name: "Poultry Meat Meal (PMM)",
                description: {
                    "Crude Protein" : "± 60%",
                    "Fat": "max 13%",
                    "Ash": "max 15%",
                }
            }
        ]
    },
    {
        category: "Blood Meal",
        identifier: "blood-meals",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/bloodmeal.jpeg",
                name: "Blood Meal",
                description: {
                    "Crude Protein" : "± 90%",
                    "Fat": "max 2%",
                    "Ash": "max 3%",
                }
            },
        ]
    },
    {
        category: "Bone Meal",
        identifier: "bone-meal",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/bonemeal.jpeg",
                name: "Bone Meal",
                description: {
                    "Protein": "25.54%",
                    "Kalsium": "46.34%",
                    "Lemak": "3.80%",
                    "Serat": "1.80%",
                    "Air": "5.52%",
                    "Phosphate": "17%"
                }
            }
        ]
    },
    {
        category: "Soybean Meal (SBM)",
        identifier: "sbm",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/sbm.jpg",
                name: "Soy Bean Meal (SBM)",
                description: {
                    "Protein": "45%",
                    "Lemak": "1.99%",
                    "Ash": "6.10%"
                }
            }
        ]
    },
    {
        category: "Garam",
        identifier: "garam",
        // subCategory: [
        //   {
        //     name: "Garam Halus",
        //     identifier: "garam-halus",
        //     products: [
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product One",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Two",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Three",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Four",
        //         }
        //     ]
        //   },
        //   {
        //     name: "Garam Kasar",
        //     identifier: "garam-kasar",
        //     products: [
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product One",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Two",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Three",
        //         },
        //         {
        //             image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
        //             name: "Product Four",
        //         }
        //     ]
        //   }
        // ]
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garam-kasar.jpeg",
                name: "Garam Kasar",
                description: {
                    "Nacl" : "98.00%",
                    "Moist" : "0.44%"
                }
            },
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/garamhalus.jpg",
                name: "Garam Halus",
                description: {
                    "Nacl" : "98.00%",
                    "Moist" : "0.44%"
                }
            },
        ]
    },
    {
        category: "Fish Meal (FM)",
        identifier: "fmp",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/fishmeal.jpg",
                name: "Fish Meal (FM)",
                description: {
                    "Pro" : "55%",
                    "Moist" : "10%",
                    "Fat" : "12%",
                    "Ash" : "20%",
                    "Ca" : "6%",
                    "Phospor" : "5%",
                    "Pepsin" : "75%",
                    "Tvbn" : "150%"
                }
            },
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/fishmeal.jpg",
                name: "Fish Meal (FM) 60",
                description: {
                    "Pro" : "60%",
                    "Moist" : "10%",
                    "Fat" : "12%",
                    "Ash" : "19%",
                    "Ca" : "6%",
                    "Phospor" : "3.5%",
                    "Pepsin" : "80%",
                    "Tvbn" : "120%"
                }
            },
        ]
    },
    {
        category: "Chicken Feather Meal (CFM)",
        identifier: "cfm",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/chikenfeathermeal.jpg",
                name: "Chicken Feather Meal (CFM)",
                description: {
                    "Crude Protein" : "± 80%",
                    "Fat": "max 12%",
                    "Ash": "max 5%",
                }
            }
        ]
    },
    {
        category: "Energy Meal",
        identifier: "energy-meal",
        products: [
            {
                image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/product/energymeal.jpg",
                name: "Energy Meal",
                description: {
                    "Moist" : "8%",
                    "Fat" : "10%",
                }
            }
        ]
    },
]

function Product ({
    pages=true
}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        const href = window.location.href.substring(
          window.location.href.lastIndexOf('#') + 1,
        );
        if (window.location.href.lastIndexOf('#') > 0) {
            const element = document.getElementById(href);
            const offset = 100;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            // document.getElementById(href)?.scrollIntoView();
            console.log('offsetPosition', offsetPosition)
            setTimeout(function () {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 5)
        }
    }, [window.location.href]);
    return (
        <>
            {
                pages &&
                <DefaultNavbar
                    // routes={routes}
                    // action={{
                    //   type: "external",
                    //   route: "https://www.creative-tim.com/product/material-kit-react",
                    //   label: "free download",
                    //   color: "info",
                    // }}
                    sticky
                />
            }
            {
                !pages && 
                <MKBox
                    minHeight="40vh"
                    width="100%"
                    bgColor="primary"
                    sx={{
                    // backgroundImage: `url(${bgImage})`,
                    // backgroundColor: "#f69a18",
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                        display: "grid",
                        placeItems: "center",
                        borderRadius: 6,
                    }}
                >
                    <Container>
                        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                            <MKTypography
                                variant="h1"
                                color="white"
                                mt={-6}
                                mb={1}
                            >
                                <div style={{ width: '100%', textAlign: 'center', marginBottom: 10 }}>
                                    <div style={{backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto', 
                                        width: 50, height: 5}}/>
                                </div>
                                <div style={{fontSize: 30}}>
                                    Produk Terbaik Kualitas Internasional
                                </div>
                            </MKTypography>
                            {/* <MKTypography
                                variant="body1"
                                color="white"
                                textAlign="center"
                                px={{ xs: 6, lg: 12 }}
                                mt={1}
                                >
                                Free & Open Source Web UI Kit built over ReactJS &amp; MUI. Join over 1.6 million
                                developers around the world.
                            </MKTypography> */}
                        </Grid>
                    </Container>
                </MKBox>
            }
            {
                !pages &&
                <MKBox mt={{ xs: -10, md: -15 }} sx={{paddingLeft: 1, paddingRight: 1}} textAlign="center">
                    <MKBox 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            backgroundColor: "#fab95f", borderRadius: 5,
                            maxWidth: '80%', minHeight: 300, marginLeft: 'auto', marginRight: 'auto'}}
                        >
                            <Grid container justifyContent="center" alignItems={"center"} textAlign={"center"}>
                                <Grid item xs={12} lg={6}>
                                    <img src={productImage} width={'95%'} height={"auto"} style={{marginTop: 10, borderRadius: 10 }}/>
                                </Grid>
                                <Grid item xs={12} lg={6} >
                                    <MKTypography
                                        variant="h4"
                                        textAlign="center"
                                        px={{ xs: 6, lg: 12 }}
                                        sx={{
                                            fontSize: {xs: 15, md: 24}
                                        }}
                                    >
                                        Produk Kami
                                    </MKTypography>
                                    <MKTypography
                                        variant="h3"
                                        textAlign="center"
                                        px={{ xs: 6, lg: 12 }}
                                        sx={{
                                            fontSize: {xs: 24, md: 32}
                                        }}
                                    >
                                        Kualitas Terbaik dan Terpercaya
                                    </MKTypography>
                                    <MKTypography
                                        variant="body2"
                                        textAlign="center"
                                        px={{ xs: 6, lg: 12 }}
                                        sx={{
                                            mt: {xs: 5},
                                            fontSize: {xs: 15, md: 18}
                                        }}
                                    >
                                        Lihat Produk Lainnya
                                    </MKTypography>
                                    <MKButton
                                        sx={{
                                            mt: 2,
                                            borderRadius: 10,
                                            mb: {xs: 5}
                                        }}
                                    >
                                        <MKTypography
                                            variant="h6"
                                            textAlign="center"
                                            onClick={() => {
                                                window.location.href = "/products"
                                            }}
                                        >
                                            Produk Lainnya
                                        </MKTypography>
                                    </MKButton>
                                </Grid>
                            </Grid>
                    </MKBox>
                </MKBox>
            }
            {
                pages &&
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.primary.main, 0.8),
                            rgba(gradients.light.state, 0.8)
                        )}, url(${productImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <MKTypography
                        variant="h1"
                        color="white"
                        mt={6}
                        mb={1}
                        textAlign="center"
                    >
                        <div style={{fontSize: 32, color: "white", marginBottom: 20}}>
                            Produk Kami
                        </div>
                        <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                            Produk terbaik kualitas internasional
                        </div>
                    </MKTypography>
                </MKBox>
            }
            <MKBox component="section" py={{ xs: 3, md: 12 }}>
                {
                    !pages &&
                        <Container>
                            <Grid container>
                                {
                                    modalProduct.map( value => {
                                        return (
                                            <Grid item xs={6} md={6} lg={3} sx={{ mb: { xs: 3, lg: 0 }, p: {xs: 1, md: 2, lg: 2} }} key={value.name}>
                                                {/* <Link to={route}> */}
                                                    <ExampleCard image={value.image} title={value.name} display="grid" 
                                                        imageMaxHeight={250}
                                                        imageMinHeight={250}
                                                        minHeight="auto" />
                                                {/* </Link> */}
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Container>
                }
                {
                    pages &&
                    <Container>
                        <Grid container>
                        {
                            modalProductList.map( v => {
                                return (
                                    <Grid item xs={12} md={12}>
                                        <Card
                                            sx={{
                                                p: 2,
                                                mx: { xs: 2, lg: 3 },
                                                mt: 2,
                                                mb: 4,
                                                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                                                backdropFilter: "saturate(200%) blur(30px)",
                                                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                                            }}>
                                            <Grid container key={v.identifier} id={v.identifier}>
                                                <Grid item xs={12} md={12}>
                                                    {v.category}
                                                </Grid>
                                                <div style={{width: '10%', height: 5, backgroundColor: '#f69a18'}}/>
                                                <Grid container xs={12} md={12}>
                                                    {
                                                        v.products && v.products.map( value => {
                                                            return (
                                                                <>
                                                                    <Grid item xs={12} md={6} lg={3} sx={{ mb: { xs: 3, lg: 0 }, p: {xs: 1, md: 2, lg: 2} }} key={value.name}>
                                                                        {/* <Link to={route}> */}
                                                                            <ExampleCard image={value.image} display="grid" title={value.name}
                                                                                imageMaxHeight={250}
                                                                                imageMinHeight={250}
                                                                                minHeight="auto" />
                                                                        {/* </Link> */}
                                                                    </Grid>
                                                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                                                        <div>
                                                                            {value.description && <div>Spesifikasi :</div>}
                                                                            {value.description && Object.keys(value.description).map( v => {
                                                                                return(<div style={{display: 'flex', fontSize: 15}}>
                                                                                    <div style={{minWidth: 140}}>{v}</div>
                                                                                    <div>:{value.description[v]}</div>
                                                                                </div>)
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        v.subCategory && v.subCategory.map(subV => {
                                                            return (
                                                                <Grid style={{paddingLeft: 10, paddingTop: 10}} container key={subV.identifier} id={subV.identifier}>
                                                                    <Grid item xs={12} md={12}>
                                                                        {subV.name}
                                                                    </Grid>
                                                                    <div style={{width: '5%', height: 5, backgroundColor: '#f69a18'}}/>
                                                                    <Grid container xs={12} md={12} style={{backgroundColor: 'primary'}}>
                                                                        {
                                                                            subV.products && subV.products.map( value => {
                                                                                return (
                                                                                    <Grid item xs={6} md={2} sx={{ mb: { xs: 3, lg: 0 }, p: {xs: 1, md: 2, lg: 2} }} key={value.name}>
                                                                                        {/* <Link to={route}> */}
                                                                                            <ExampleCard image={value.image} display="grid" title={value.name}
                                                                                                imageMaxHeight={250}
                                                                                                imageMinHeight={250}
                                                                                                minHeight="auto" />
                                                                                        {/* </Link> */}
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid> 
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </Container>
                }
            </MKBox>
            {
                pages &&
                <MKBox pt={6} px={1} mt={6}>
                    <DefaultFooter content={footerRoutes} />
                </MKBox>
            }
        </>
    )
}

export default Product