// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";

// Sections
import PageHeaders from "layouts/sections/page-sections/page-headers";
import Features from "layouts/sections/page-sections/featuers";
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Inputs from "layouts/sections/input-areas/inputs";
import Forms from "layouts/sections/input-areas/forms";
import Alerts from "layouts/sections/attention-catchers/alerts";
import Modals from "layouts/sections/attention-catchers/modals";
import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
import Avatars from "layouts/sections/elements/avatars";
import Badges from "layouts/sections/elements/badges";
import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
import Buttons from "layouts/sections/elements/buttons";
import Dropdowns from "layouts/sections/elements/dropdowns";
import ProgressBars from "layouts/sections/elements/progress-bars";
import Toggles from "layouts/sections/elements/toggles";
import Typography from "layouts/sections/elements/typography";

const sbdaRoutes = [
  {
    name: "Home",
    route: "/",
    // component: <SignIn />,
  },
  {
    name: "All Product",
    route: "/products",
    // component: <SignIn />,
    collapse: [
      {
        name: "Meat Bone Meal (MBM)",
        route: "/products/#mbm",
        // component: <SignIn />,
      },
      {
        name: "Poultry Meat Meal (PMM)",
        route: "/products/#pmm",
        // component: <SignIn />,
      },
      {
        name: "Blood Meal",
        route: "/products/#blood-meals",
        // component: <SignIn />,
      },
      {
        name: "Bone Meal",
        route: "/products/#bone-meal",
        // component: <SignIn />,
      },
      {
        name: "Soybean Meal (SBM)",
        route: "/products/#sbm",
        // component: <SignIn />,
      },
      {
        name: "Garam",
        route: "/products/#garam",
        // component: <SignIn />,
        dropdown: true,
        collapse: [
          {
            name: "Garam Halus",
            // route: "/products/#garam-halus",
            route: "/products/#garam",
            // component: <SignIn />,
          },
          {
            name: "Garam Kasar",
            // route: "/products/#garam-kasar",
            route: "/products/#garam",
            // component: <SignIn />,
          }
        ]
      },
      {
        name: "Fish Meal (FM)",
        route: "/products/#fmp",
        // component: <SignIn />,
      },
      {
        name: "Chicken Feather Meal (CFM)",
        route: "/products/#cfm",
        // component: <SignIn />,
      },
      {
        name: "Energy Meal",
        route: "/products/#energy-meal",
        // component: <SignIn />,
      },
    ]
  },
  {
    name: "Info Kami",
    route: "/about-us",
    // component: <SignIn />,
    collapse: [
      {
        name: "Pelanggan Kami",
        route: "/about-us/#our-customer",
      },
      {
        name: "Team Kami",
        route: "/about-us/#our-team",
      },
    ]
  },
  {
    name: "Gallery",
    route: "/gallery",
    // component: <SignIn />,
  },
  {
    name: "Hubungi Kami",
    route: "/contact-us",
    // component: <SignIn />,
    // collapse: [
    //   {
    //     name: "Pelanggan Kami",
    //     route: "/about-us",
    //   },
    //   {
    //     name: "Team Kami",
    //     route: "/about-us",
    //   },
    // ]
  }
];

export default sbdaRoutes;
