import React, { useEffect } from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import DefaultFooter from "examples/Footers/DefaultFooter";
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard';
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

import productImages from "../assets/product-images/product-image.jpeg";

import footerRoutes from "footer.routes";
import Customer from './Customer';

const OurTeam = [
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/team/celine.jpeg",
        name: "Celline",
        position: "SPV Marketing",
        quotes: "Masa depan itu tergantung  pada apa yang sedang kamu lakukan hari ini."
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/team/agung.png",
        name: "Agung",
        position: "Marketing",
        quotes: "Jangan katakan aku tidak bisa, tapi katakan aku pasti bisa!! Hasil yang hebat muncul dari pikiran yang sehat!"
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/team/ilham.png",
        name: "Ilham",
        position: "Marketing",
        quotes: "Pikiran melahirkan tindakan, tindakan menghasilkan kebiasaan, kebiasaan membuahkan karakter, dan karakter menciptakan nasib"
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/team/ramdhan.png",
        name: "Ramdhan",
        position: "Marketing",
        quotes: "Mengutamakan Mutu & kualitas, menjaga kepercayaan pelanggan"
    },
    {
        image: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/team/rofi_renown_putra.png",
        name: "Rofi Renown Putra",
        position: "Marketing",
        quotes: "Where there's a will there's a way"
    },
]

function AboutUs ({
    pages=true
}) {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        const href = window.location.href.substring(
          window.location.href.lastIndexOf('#') + 1,
        );
        if (window.location.href.lastIndexOf('#') > 0) {
            const element = document.getElementById(href);
            let offset = 100;
            if (href === "our-customer") { offset = -100 }
            if (href === "our-team") { offset = 10 }
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            const offsetPosition = elementPosition - offset;
            // document.getElementById(href)?.scrollIntoView();
            console.log('offsetPosition', offsetPosition)
            setTimeout(function () {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 5)
        }
    }, [window.location.href]);
    return (
        <>
            {
                pages &&
                <DefaultNavbar
                    // routes={routes}
                    // action={{
                    //   type: "external",
                    //   route: "https://www.creative-tim.com/product/material-kit-react",
                    //   label: "free download",
                    //   color: "info",
                    // }}
                    sticky
                />
            }
            {
                pages &&
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.primary.main, 0.8),
                            rgba(gradients.light.state, 0.8)
                        )}, url(${productImages})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <MKTypography
                        variant="h1"
                        color="white"
                        mt={6}
                        mb={1}
                        textAlign="center"
                    >
                        <div style={{fontSize: 32, color: "white", marginBottom: 20}}>
                            Info Kami
                        </div>
                        {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                            
                        </div> */}
                    </MKTypography>
                </MKBox>
            }
            <MKBox component="section" py={{ xs: 3, md: 12 }} id='about-us'>
                <Container>
                    <Grid container alignItems="center">
                        <Grid item xs={12} lg={5}>
                            <MKTypography variant="h3" my={1}>
                                Tentang Kami
                            </MKTypography>
                            <MKTypography variant="body2" color="" mb={2}>
                                Berdiri Sejak tahun 2018, Kami mendistribusikan bahan pakan ternak yang berkualitas terbaik ke berbagai pasar, 
                                peternakan, industri pabrik pengelola pakan. 
                                Kami berkomitment untuk melayani pelanggan dengan sepenuh hati.
                            </MKTypography>
                            {/* <MKTypography
                                component="a"
                                href="#"
                                variant="body2"
                                color="primary"
                                fontWeight="regular"
                                sx={{
                                    width: "max-content",
                                    display: "flex",
                                    alignItems: "center",

                                    "& .material-icons-round": {
                                    fontSize: "1.125rem",
                                    transform: "translateX(3px)",
                                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                    },

                                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                                    transform: "translateX(6px)",
                                    },
                                }}
                                >
                                    More about us
                                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                            </MKTypography> */}
                            <MKTypography variant="h3" my={1}>
                                Tujuan Kami
                            </MKTypography>
                            <MKTypography variant="body2" color="" mb={2}>
                                Memberikan produk dengan kualitas terbaik
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 2, lg: 0 } }}>
                            <Stack>
                                <MKBox display="flex" alignItems="center" p={2}>
                                    <MKBox
                                        width="3rem"
                                        height="3rem"
                                        variant="gradient"
                                        bgColor="primary"
                                        color="white"
                                        coloredShadow="info"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="xl"
                                        >
                                        S
                                    </MKBox>
                                    <MKTypography variant="body" color="text" pl={2}>
                                        Service
                                    </MKTypography>
                                </MKBox>
                                <MKBox display="flex" alignItems="center" p={2}>
                                    <MKBox
                                        width="3rem"
                                        height="3rem"
                                        variant="gradient"
                                        bgColor="primary"
                                        color="white"
                                        coloredShadow="info"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="xl"
                                        >
                                        B
                                    </MKBox>
                                    <MKTypography variant="body" color="text" pl={2}>
                                        Best Quality
                                    </MKTypography>
                                </MKBox>
                                <MKBox display="flex" alignItems="center" p={2}>
                                    <MKBox
                                        width="3rem"
                                        height="3rem"
                                        variant="gradient"
                                        bgColor="primary"
                                        color="white"
                                        coloredShadow="info"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="xl"
                                        >
                                        D
                                    </MKBox>
                                    <MKTypography variant="body" color="text" pl={2}>
                                        Delivery Good
                                    </MKTypography>
                                </MKBox>
                                <MKBox display="flex" alignItems="center" p={2}>
                                    <MKBox
                                        width="3rem"
                                        height="3rem"
                                        variant="gradient"
                                        bgColor="primary"
                                        color="white"
                                        coloredShadow="info"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        borderRadius="xl"
                                        >
                                        A
                                    </MKBox>
                                    <MKTypography variant="body" color="text" pl={2}>
                                        Available
                                    </MKTypography>
                                </MKBox>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={3} sx={{ ml: { lg: "auto" }, mt: { xs: 2, lg: 0 } }}>
                            <MKTypography variant="body2" color="" mb={2}>
                                Merupakan prinsip dasar Perusahaan kami dalam menjalankan etika bisnis 
                                baik internal maupun eksternal ditambah dengan tim kami yang saling membantu satu dengan yang lain.
                            </MKTypography>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            {
                pages &&
                <MKBox id="our-customer" component="section" py={{ xs: 3, md: 12 }}>
                    <Customer pages={false}/>
                </MKBox>
            }
            {
                pages &&
                <MKBox id="our-team" component="section" py={{ xs: 3, md: 12 }}>
                    <Container>
                        <Grid container>
                            <Grid item xs={12} lg={12} alignItems={"center"} textAlign={"center"}
                                sx={{ p: 5, pt: 0 }}
                            >
                                <MKTypography variant="h3" my={1}>
                                    Team Kami
                                </MKTypography>
                            </Grid>
                            <Grid item xs={12} lg={12} sx={{ mt: { xs: 3, lg: 0 }, p: 1 }}>
                                <Grid container alignItems={"center"} textAlign={"center"}>
                                    <Grid item xs={12} md={4} ml={'auto'} mr={'auto'}>
                                        <CenteredBlogCard
                                            image={OurTeam[0].image}
                                            title={
                                                <div>
                                                    <div>{OurTeam[0].name}</div>
                                                    <div style={{fontWeight: 'bold'}}>{OurTeam[0].position}</div>
                                                </div>
                                            }
                                            description={
                                                <div>
                                                    "{OurTeam[0].quotes}"
                                                </div>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} lg={3} ml={'auto'} sx={{ mt: { xs: 3, lg: 3 }, p: 1 }}>
                                <CenteredBlogCard
                                    image={OurTeam[1].image}
                                    title={
                                        <div>
                                            <div>{OurTeam[1].name}</div>
                                            <div style={{fontWeight: 'bold'}}>{OurTeam[1].position}</div>
                                        </div>
                                    }
                                    description={
                                        <div style={{paddingTop: 18, paddingBottom: 18}}>
                                            "{OurTeam[1].quotes}"
                                        </div>
                                    }
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} lg={3} mr={'auto'} sx={{ mt: { xs: 3, lg: 3 }, p: 1 }}>
                                <CenteredBlogCard
                                    image={OurTeam[2].image}
                                    title={
                                        <div>
                                            <div>{OurTeam[2].name}</div>
                                            <div style={{fontWeight: 'bold'}}>{OurTeam[2].position}</div>
                                        </div>
                                    }
                                    description={
                                        <div style={{paddingTop: 0, paddingBottom: 0}}>
                                            <div>
                                                "{OurTeam[2].quotes}"
                                            </div>
                                            <span>-Aristoteles</span>
                                        </div>
                                    }
                                />
                            </Grid> */}
                            {/* <Grid item xs={12} lg={3} mr={'auto'} sx={{ mt: { xs: 3, lg: 3 }, p: 1 }}>
                                <CenteredBlogCard
                                    image={OurTeam[3].image}
                                    title={
                                        <div>
                                            <div>{OurTeam[3].name}</div>
                                            <div style={{fontWeight: 'bold'}}>{OurTeam[3].position}</div>
                                        </div>
                                    }
                                    description={
                                        <div style={{paddingTop: 40, paddingBottom: 40}}>
                                            "{OurTeam[3].quotes}"
                                        </div>
                                    }
                                />
                            </Grid> */}
                            <Grid item xs={12} lg={3} mr={'auto'} sx={{ mt: { xs: 3, lg: 3 }, p: 1 }}>
                                <CenteredBlogCard
                                    image={OurTeam[4].image}
                                    title={
                                        <div>
                                            <div>{OurTeam[4].name}</div>
                                            <div style={{fontWeight: 'bold'}}>{OurTeam[4].position}</div>
                                        </div>
                                    }
                                    description={
                                        <div style={{paddingTop: 53, paddingBottom: 53}}>
                                            "{OurTeam[4].quotes}"
                                        </div>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox>
            }
            {
                pages &&
                <MKBox pt={6} px={1} mt={6}>
                    <DefaultFooter content={footerRoutes} />
                </MKBox>
            }
        </>
    )
}

export default AboutUs