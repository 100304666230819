import React from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Images
import productImages from "../assets/product-images/product-image.jpeg";
import footerRoutes from "footer.routes";

import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

import PhotoAlbum from "react-photo-album"

const photos = [
    {
        category: "Gudang 1",
        photos: [
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                width: 1000,
                height: 1000
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu-1.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu-1.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu-1.jpeg",
                width: 900,
                height: 1600
            }
        ]
    },
    {
        category: "Gudang 2",
        photos: [
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua.jpeg",
                width: 1000,
                height: 1000
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua-1.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua-1.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkedua-1.jpeg",
                width: 900,
                height: 1600
            },
        ]
    },
    {
        category: "Gudang 3",
        photos: [
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga.jpeg",
                width: 1000,
                height: 1000
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga-1.jpeg",
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga-1.jpeg",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangketiga-1.jpeg",
                width: 900,
                height: 1600
            },
        ]
    },
    {
        category: "Bongkar",
        photos: [
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/GUDANG.jpeg",
                width: 800,
                height: 1000
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/GUDANG1.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/GUDANG2.jpeg",
                width: 1600,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/GUDANG3.jpeg",
                width: 800,
                height: 1200
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang4.jpeg",
                width: 800,
                height: 1000
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang5.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang6.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang7.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang8.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang9.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang10.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang11.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang12.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang13.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang14.jpeg",
                width: 900,
                height: 1600
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang15.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang16.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang17.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang18.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang19.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang20.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang21.jpeg",
                width: 800,
                height: 800
            },
            {
                src: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudang22.jpeg",
                width: 800,
                height: 800
            }
        ]
    },
    {
        category: "Bongkar",
        photos: [
            {
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar-1.mp4",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                embedUrl: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar-1.mp4",
                renderItem: renderVideoUrl.bind("https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar-1.mp4")
            },
            {
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar.mp4",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                embedUrl: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar.mp4",
                renderItem: renderVideoUrl.bind("https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/videobongkar.mp4")
            },
            {
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_2.mp4",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                embedUrl: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_2.mp4",
                renderItem: renderVideoUrl.bind("https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_2.mp4")
            },
            {
                original: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_1.mp4",
                thumbnail: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/gudangkesatu.jpeg",
                embedUrl: "https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_1.mp4",
                renderItem: renderVideoUrl.bind("https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/gudang/video_bongkar_1.mp4")
            }
        ]
    }
];

function Gallery ({
    pages=true
}) {
    return (
        <>
            {
                pages &&
                <DefaultNavbar
                    // routes={routes}
                    // action={{
                    //   type: "external",
                    //   route: "https://www.creative-tim.com/product/material-kit-react",
                    //   label: "free download",
                    //   color: "info",
                    // }}
                    sticky
                />
            }
            <MKBox
                minHeight="25rem"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    `${linearGradient(
                        rgba(gradients.primary.main, 0.8),
                        rgba(gradients.light.state, 0.8)
                    )}, url(${productImages})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <MKTypography
                    variant="h1"
                    color="white"
                    mt={6}
                    mb={1}
                    textAlign="center"
                >
                    <div style={{fontSize: 32, color: "white", marginBottom: 20}}>
                        Galeri
                    </div>
                    {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                        
                    </div> */}
                </MKTypography>
            </MKBox>
            <MKBox component="section" py={{ xs: 3, md: 12 }}>
                <Container>
                    {
                        (!photos || photos.length < 0) &&
                        <Grid
                            container
                            item
                            xs={12}
                            lg={6}
                            justifyContent="center"
                            sx={{ mx: "auto", textAlign: "center", mt: 6 }}
                        >
                            {/* <MKTypography variant="h2">Galeri Kosong</MKTypography> */}
                            <MKTypography variant="h2" color="primary" textGradient mb={2}>
                                Galeri Kosong
                            </MKTypography>
                            {/* <MKTypography variant="body1" color="text" mb={2}>
                                Banyak pelanggan telah memilih kami sebagai penyedia produk pakan ternak yang berkualitas terbaik ke berbagai pasar, peternakan, industri pabrik pengelola pakan
                            </MKTypography> */}
                        </Grid>
                    }
                    <Grid container>
                        <Grid item xs={12} md={12} pr={1}>
                            <MKTypography
                                variant="h1"
                                color="rgba(246, 154, 24, 0.8)"
                                mt={6}
                                mb={1}
                                textAlign="center"
                            >
                                <div style={{fontSize: 32, color: "rgba(246, 154, 24, 0.8)", marginBottom: 20}}>
                                    Galeri {photos[0].category}
                                </div>
                                {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                                    
                                </div> */}
                            </MKTypography>
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                            <ImageGallery showPlayButton={false} autoPlay={true} items={photos[0].photos} />
                            {/* <PhotoAlbum layout="columns" photos={photos[0].photos} /> */}
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                        </Grid>
                        <Grid item xs={12} md={12} pr={1}>
                            <MKTypography
                                variant="h1"
                                color="rgba(246, 154, 24, 0.8)"
                                mt={6}
                                mb={1}
                                textAlign="center"
                            >
                                <div style={{fontSize: 32, color: "rgba(246, 154, 24, 0.8)", marginBottom: 20}}>
                                    Galeri {photos[1].category}
                                </div>
                                {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                                    
                                </div> */}
                            </MKTypography>
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                            <ImageGallery showPlayButton={false} autoPlay={true} items={photos[1].photos} />
                            {/* <PhotoAlbum layout="columns" photos={photos[1].photos} /> */}
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                        </Grid>
                        <Grid item xs={12} md={12} pr={1}>
                            <MKTypography
                                variant="h1"
                                color="rgba(246, 154, 24, 0.8)"
                                mt={6}
                                mb={1}
                                textAlign="center"
                            >
                                <div style={{fontSize: 32, color: "rgba(246, 154, 24, 0.8)", marginBottom: 20}}>
                                    Galeri {photos[2].category}
                                </div>
                                {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                                    
                                </div> */}
                            </MKTypography>
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                            <ImageGallery showPlayButton={false} autoPlay={true} items={photos[2].photos} />
                            {/* <PhotoAlbum layout="columns" photos={photos[2].photos} /> */}
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                        </Grid>
                        <Grid item xs={12} md={12} pr={1}>
                            <MKTypography
                                variant="h1"
                                color="rgba(246, 154, 24, 0.8)"
                                mt={6}
                                mb={1}
                                textAlign="center"
                            >
                                <div style={{fontSize: 32, color: "rgba(246, 154, 24, 0.8)", marginBottom: 20}}>
                                    Video {photos[4].category}
                                </div>
                                {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                                    
                                </div> */}
                            </MKTypography>
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                            <ImageGallery showPlayButton={false} autoPlay={false} items={photos[4].photos} />
                            {/* <PhotoAlbum layout="columns" photos={photos[2].photos} /> */}
                            <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                        </Grid>
                    </Grid>
                    <MKTypography
                        variant="h1"
                        color="rgba(246, 154, 24, 0.8)"
                        mt={6}
                        mb={1}
                        textAlign="center"
                    >
                        <div style={{fontSize: 32, color: "rgba(246, 154, 24, 0.8)", marginBottom: 20}}>
                            Galeri {photos[3].category}
                        </div>
                        {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                            
                        </div> */}
                    </MKTypography>
                    <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                    <PhotoAlbum layout="columns" photos={photos[3].photos} />
                    <div style={{height: 10, backgroundColor: 'rgba(246, 154, 24, 0.8)'}}/>
                </Container>
            </MKBox>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    )
}

function renderVideoUrl (video) {
    return (
      <video controls muted height={400}>
        <source src={video.original} />
      </video>
    )
}

export default Gallery