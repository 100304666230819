import React from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";
// import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

// Images
import appleLogo from "assets/images/logos/gray-logos/logo-apple.svg";
import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

import footerRoutes from "footer.routes";

function Customer ({
    pages=true
}) {
    return (
        <>
            {
                pages &&
                <DefaultNavbar
                    // routes={routes}
                    // action={{
                    //   type: "external",
                    //   route: "https://www.creative-tim.com/product/material-kit-react",
                    //   label: "free download",
                    //   color: "info",
                    // }}
                    sticky
                />
            }
            <MKBox component="section" py={{ xs: 3, md: 12 }}>
                <Container>
                    <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
                        <Grid item xs={12} md={4}>
                            <DefaultCounterCard
                            count={70}
                            suffix="+"
                            title="Pelanggan"
                            description="Pelanggan yang sanagat puas dengan produk kami"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} display="flex">
                            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
                            <DefaultCounterCard
                            count={9}
                            suffix="+"
                            title="Produk"
                            description="Menyediakan produk dengan kualitas terbaik"
                            />
                            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DefaultCounterCard
                            count={1}
                            title="Indonesia"
                            description="Nomor 1 Penyedia produk pakan di Indonesia"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={6}
                        justifyContent="center"
                        sx={{ mx: "auto", textAlign: "center", mt: 6 }}
                    >
                        <MKTypography variant="h2">Dipercaya oleh lebih dari</MKTypography>
                        <MKTypography variant="h2" color="primary" textGradient mb={2}>
                            70 Pelanggan kami
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                            Banyak pelanggan telah memilih kami sebagai penyedia produk pakan ternak yang berkualitas terbaik ke berbagai pasar, peternakan, industri pabrik pengelola pakan
                        </MKTypography>
                    </Grid>
                    {/* <Grid container spacing={3} sx={{ mt: 8 }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <DefaultReviewCard
                                name="Nick Willever"
                                date="1 day ago"
                                review="This is an excellent product, the documentation is excellent and helped me get things done more efficiently."
                                rating={5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <DefaultReviewCard
                                color="primary"
                                name="Shailesh Kushwaha"
                                date="1 week ago"
                                review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
                                rating={5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <DefaultReviewCard
                                name="Samuel Kamuli"
                                date="3 weeks ago"
                                review="Great product. Helped me cut the time to set up a site. I used the components within instead of starting from scratch. I highly recommend for developers who want to spend more time on the backend!."
                                rating={5}
                            />
                        </Grid>
                    </Grid> */}
                    <Divider sx={{ my: 6 }} />
                    <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={6} md={4} lg={2} textAlign={"center"} alignItems={"center"}>
                        <MKBox component="img" src={"https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/customer/pokphand.png"} alt="Facebook" width={150} maxHeight={180} opacity={0.6} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} textAlign={"center"} alignItems={"center"}>
                        <MKBox component="img" src={"https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/customer/cargill.png"} alt="Nasa" width={150} maxHeight={180} opacity={0.6} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} textAlign={"center"} alignItems={"center"}>
                        <MKBox component="img" src={"https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/customer/goldcoin.png"} alt="Apple" width={150} maxHeight={180} opacity={0.6} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} textAlign={"center"} alignItems={"center"}>
                        <MKBox component="img" src={"https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/customer/citrafeed.png"} alt="Vodafone" width={150} maxHeight={180} opacity={0.6} />
                    </Grid>
                    <Grid item xs={6} md={4} lg={2} textAlign={"center"} alignItems={"center"}>
                        <MKBox
                            component="img"
                            src={"https://wuent.s3.ap-southeast-1.amazonaws.com/companyprofile/sinarbudidayaalam/customer/sekargolden.png"}
                            alt="DigitalOcean"
                            width={150}
                            maxHeight={180}
                            opacity={0.6}
                        />
                    </Grid>
                    </Grid>
                </Container>
            </MKBox>
        </>
    )
}

export default Customer