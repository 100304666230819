import React, { useState } from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ToastContainer, toast } from 'react-toastify';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from 'components/MKButton';
import MKInput from 'components/MKInput';
import MKTypography from "components/MKTypography";
import { contactUs } from "../service/service";
// import MKSocialButton from "components/MKSocialButton";

import productImages from "../assets/product-images/product-image.jpeg";
import Select from 'react-select';
import footerRoutes from "footer.routes";


const productOptions = [
    {
        label: "Meat Bone Meal (MBM)",
        value: "mbm",
    },
    {
        label: "Poultry Meat Meal (PMM)",
        value: "pmm",
    },
    {
        label: "Soybean Meal (SBM)",
        value: "sbm",
    },
    {
        label: "Garam Kasar",
        value: "garam",
    },
    {
        label: "Garam Halus",
        value: "garam",
    },
    {
        label: "Fish Meal (FM)",
        value: "fmp",
    },
    {
        label: "Chicken Feather Meal (CFM)",
        value: "cfm",
    },
    {
        label: "Energy Meal",
        value: "energy-meal",
    },
]

function ContactUs ({
    pages=true
}) {

    const [ form, setForm ] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        company: "",
        about: productOptions[0],
        description: "",
        emailRegex: false,
    })
    
    const onChange = (e) => {
        console.log(e)
        if (e.email) {
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            if (!emailRegex.test(e.email)) {
                e = {
                    ...e,
                    emailRegex: true
                }
            } else {
                e = {
                    ...e,
                    emailRegex: false
                }
            }
        }
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const sendForm = async () => {
        if (!form.name || !form.phoneNumber) {
            toast.error('Nama dan Nomor Telepon wajib diisi', {
                toastId: 'profileupdate-error'
            });
            return;
        }
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (form.email && !emailRegex.test(form.email)) {
            toast.error('Masukkan email dengan format yang valid', {
                toastId: 'profileupdate-error'
            });
            return;
        }
        let newForm = {
            ...form,
            about: form.about ? form.about.label : ''
        }
        let {data, error} = await contactUs(newForm)
        if ( error ) {
            toast.error('Terjadi kesalahan', {
                toastId: 'profileupdate-error'
            });
        } else {
            setForm({
                name: "",
                email: "",
                phoneNumber: "",
                company: "",
                about: "",
                description: "",
            })
            toast.success('Tim kami akan segera menghubungi kamu', {
                toastId: 'contactus'
            });
        }
    }

    return (
        <>
            {
                pages &&
                <DefaultNavbar
                    // routes={routes}
                    // action={{
                    //   type: "external",
                    //   route: "https://www.creative-tim.com/product/material-kit-react",
                    //   label: "free download",
                    //   color: "info",
                    // }}
                    sticky
                />
            }
            {
                pages &&
                <MKBox
                    minHeight="25rem"
                    width="100%"
                    sx={{
                        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.primary.main, 0.8),
                            rgba(gradients.light.state, 0.8)
                        )}, url(${productImages})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <MKTypography
                        variant="h1"
                        color="white"
                        mt={6}
                        mb={1}
                        textAlign="center"
                    >
                        <div style={{fontSize: 32, color: "white", marginBottom: 20}}>
                            Hubungi Kami
                        </div>
                        {/* <div style={{fontSize: 24, color: "white", marginBottom: 20}}>
                            
                        </div> */}
                    </MKTypography>
                </MKBox>
            }
            <MKBox component="section" py={{ xs: 0, lg: 6 }}>
                <Container>
                    <Grid container item>
                    <MKBox
                        width="100%"
                        bgColor="white"
                        borderRadius="xl"
                        shadow="xl"
                        mb={6}
                        sx={{ overflow: "hidden" }}
                    >
                        <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            lg={5}
                            position="relative"
                            px={0}
                            sx={{
                                backgroundColor: 'white'
                            // backgroundImage: ({
                            //     palette: { gradients },
                            //     functions: { rgba, linearGradient },
                            // }) =>
                            //     `${linearGradient(
                            //     rgba(gradients.primary.main, 1),
                            //     rgba(gradients.primary.state, 1)
                            //     )}, url(${productImages})`,
                            // backgroundSize: "cover",
                            }}
                        >
                            <MKBox
                                // display="flex"
                                justifyContent="center"
                                alignItems="center"
                                width="100%"
                                height="100%"
                            >
                            <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} sx={{mt: 3}} my="auto">
                                <MKTypography variant="h3" mb={1}>
                                    Contact Us
                                </MKTypography>
                                <MKTypography variant="body2" opacity={0.8} mb={3}>
                                    Masukkan informasi anda dan team kami akan menghubungi anda dalam 24 Jam Kerja.
                                </MKTypography>
                                <MKBox display="flex" p={1}>
                                    <img src={productImages} width={"100%"}/>
                                </MKBox>
                                <MKBox mt={3}>

                                </MKBox>
                            </MKBox>
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <MKBox component="form" p={2} method="post">
                            <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                <MKTypography variant="h2" mb={1}>
                                    Say Hi!
                                </MKTypography>
                                <MKTypography variant="body1" color="text" mb={2}>
                                    We&apos;d like to talk with you.
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={0.5} pb={3} px={3}>
                                <Grid container>
                                <Grid item xs={12} md={6} pr={1} mb={6}>
                                    <MKInput
                                        variant="standard"
                                        label="Nama Saya *"
                                        placeholder="Nama"
                                        error={!form.name || (form.name && form.name.length < 2)}
                                        value={form.name}
                                        onChange={(e) => { onChange({name: e.currentTarget.value}) }}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} pr={1} mb={6}>
                                    <MKInput
                                        variant="standard"
                                        label="Email"
                                        type="email"
                                        value={form.email}
                                        error={form.emailRegex}
                                        onChange={(e) => { onChange({email: e.currentTarget.value}) }}
                                        placeholder="Email"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} pr={1} mb={6}>
                                    <MKInput
                                        variant="standard"
                                        label="Perusahaan"
                                        placeholder="Perusahaan Saya"
                                        value={form.company}
                                        onChange={(e) => { onChange({company: e.currentTarget.value}) }}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} pr={1} mb={6}>
                                    <MKInput
                                        variant="standard"
                                        label="Nomor WA / HP *"
                                        type="tel"
                                        error={!form.phoneNumber || (form.phoneNumber && form.phoneNumber.length < 8)}
                                        value={form.phoneNumber}
                                        onChange={(e) => { onChange({phoneNumber: e.currentTarget.value}) }}
                                        placeholder="Hubungi saya di"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} pr={1} mb={6}>
                                    {/* <MKInput
                                        variant="standard"
                                        label="Saya ingin tahu tentang"
                                        placeholder="What you love"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    /> */}
                                    <Select
                                        styles={{
                                            control: (styles) => ({ ...styles, fontSize: 14 }),
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({ ...styles, fontSize: 14 }),
                                            placeholder: (styles) => ({ ...styles, fontSize: 14 }),
                                            singleValue: (styles, { data }) => ({ ...styles, fontSize: 14 }),
                                            input: (styles) => ({ ...styles, fontSize: 14, minHeight: 35})
                                        }}
                                        options={productOptions}
                                        value={form.about}
                                        onChange={(e) => { onChange({about: e}) }}
                                    />
                                </Grid>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        variant="standard"
                                        label="Pesan"
                                        placeholder="Hubungi saya, karena saya ingin mengetahui lebih lanjut tentang ..."
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        multiline
                                        rows={6}
                                        value={form.description}
                                        onChange={(e) => { onChange({description: e.currentTarget.value}) }}
                                    />
                                </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    justifyContent="flex-end"
                                    textAlign="right"
                                    ml="auto"
                                    >
                                    <MKButton variant="gradient" color="primary" onClick={() => {
                                        sendForm()
                                    }}>
                                    Send Message
                                </MKButton>
                                </Grid>
                            </MKBox>
                            </MKBox>
                        </Grid>
                        </Grid>
                    </MKBox>
                    </Grid>
                    <ToastContainer
                        toastStyle={{ color: '#024a88', fontSize: 12 }} 
                        progressStyle={{ background: '#024a88' }}
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        draggable
                        pauseOnHover
                    />
                </Container>
                </MKBox>
            {
                pages &&
                <MKBox pt={6} px={1} mt={6}>
                    <DefaultFooter content={footerRoutes} />
                </MKBox>
            }
        </>
    )
}

export default ContactUs