import React from 'react';

// Material Kit 2 React examples
import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKSocialButton from "components/MKSocialButton";

import productImages from "../assets/product-images/product-image.jpeg";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
// import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

import footerRoutes from "footer.routes";
import AboutUs from './AboutUs';
import Product from './Product';
import Customer from './Customer';
import { Button } from '@mui/material';
import MKButton from 'components/MKButton';

function Main ({

}) {
    return (
        <>
            <DefaultNavbar
                // routes={routes}
                // action={{
                //   type: "external",
                //   route: "https://www.creative-tim.com/product/material-kit-react",
                //   label: "free download",
                //   color: "info",
                // }}
                sticky
            />
            <MKBox
                minHeight="80vh"
                width="100%"
                sx={{
                // backgroundImage: `url(${productImages})`,
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                    rgba(gradients.primary.main, 0.8),
                    rgba(gradients.light.state, 0.8)
                )}, url(${productImages})`,
                // backgroundColor: ({ palette: { primary }, functions: { rgba } }) => rgba(primary.main, 0.8),
                backgroundSize: "cover",
                backgroundPosition: "top",
                display: "grid",
                placeItems: "center",
                }}
            >
                <Container>
                    <Grid container xs={12} lg={12} justifyContent="center" mx="auto">
                        <Grid item xs={12} lg={12} justifyContent="center" textAlign={"center"} mx="auto">
                            <MKTypography
                                variant="h1"
                                color="white"
                                mt={6}
                                mb={1}
                                // sx={({ breakpoints, typography: { size } }) => ({
                                //     [breakpoints.down("md")]: {
                                //         fontSize: size["xs"],
                                //     },
                                // })}
                            >
                                {/* <div style={{fontSize: 32, color: "#344767", marginBottom: 20}}> */}
                                <div style={{fontSize: 32, color: "white", marginBottom: 20}}>
                                    Welcome to CV Sinar Budi Daya Alam
                                </div>
                                <MKButton
                                    color='primary'
                                    sx={{
                                        mt: 2,
                                        borderRadius: 10,
                                    }}
                                >
                                    <MKTypography
                                        variant="h6"
                                        textAlign="center"
                                        color="white"
                                        onClick={() => {
                                            window.location.href = '/products'
                                        }}
                                    >
                                        Produk Kami
                                    </MKTypography>
                                </MKButton>
                                {" "}
                                <MKButton
                                    sx={{
                                        mt: 2,
                                        borderRadius: 10
                                    }}
                                >
                                    <MKTypography
                                        variant="h6"
                                        textAlign="center"
                                        onClick={() => {
                                            window.location.href = "/about-us/#our-customer"
                                        }}
                                    >
                                        Pelanggan Kami
                                    </MKTypography>
                                </MKButton>
                            </MKTypography>
                            <MKTypography
                                variant="body1"
                                color="white"
                                textAlign="center"
                                px={{ xs: 6, lg: 12 }}
                                mt={1}
                            >
                                {/* Free & Open Source Web UI Kit built over ReactJS &amp; MUI. Join over 1.6 million
                                developers around the world. */}
                            </MKTypography>
                        </Grid>
                        {/* <Grid item xs={12} lg={6}>
                            <img src={productImages} style={{maxWidth: '20em', marginRight: 0, marginTop: -20, position: 'absolute'}}/>
                        </Grid> */}
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <AboutUs pages={false}/>
                <Product pages={false}/>
                <Customer pages={false}/>
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    )
}

export default Main