const Axios = require('axios');

const client = Axios.create({
  baseURL: 'https://compro-api.wuebuild.com/',
  // baseURL: 'http://127.0.0.1:5500/',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000,
});

export const contactUs = async function(body) {
    try {
      let response = await client.post('/sbda/user/contact-us', {
        name: body.name || "",
        email: body.email || "",
        phoneNumber: body.phoneNumber || "",
        company: body.company || "",
        about: body.about || "",
        description: body.description || "",
      });
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
    } catch (e) {
      return { error: e.response.data.errorCode };
    }
};