

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const routesmenus = [
    {
      name: "company",
      items: [
        { name: "about us", href: "/about-us" },
        // { name: "freebies", href: "https://www.creative-tim.com/templates/free" },
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "products",
      items: [
        { name: "products", href: "/products" },
        // { name: "illustrations", href: "https://iradesign.io/" },
        // { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
        // { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/contact-us" },
        // { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
        // { name: "custom development", href: "https://services.creative-tim.com/" },
        // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
      ],
    }
  ]
  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                {/* <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} /> */}
              </Link>
              <Grid>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.2828401928555!2d106.96155257482455!3d-6.3574237621937595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6993db83ab648f%3A0xe86d8bb2b45582fe!2sCluster%20Coatesville%20Kota%20Wisata!5e0!3m2!1sen!2sid!4v1695561150153!5m2!1sen!2sid"
                    width="100%" 
                    height="300" 
                    style={{ border: 0 }}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                />
              </Grid>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={brand.route}>
                {/* <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} /> */}
              </Link>
              <MKTypography variant="h6">{brand.name}</MKTypography>
              <MKTypography variant="body2">
              <Icon
                fontSize="small"
                sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
              >
                place
              </Icon>Perumahan Kota Wisata, Cluster Livingstone SD 5 No.3, Kel. Ciangsana, Kec. Gunung Putri, Kab. Bogor
              </MKTypography>
              <MKTypography variant="body2">
                <Icon
                  fontSize="small"
                  sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
                >
                  phone
                </Icon>082312426961</MKTypography>
              <MKTypography variant="body2">
                <Icon
                  fontSize="small"
                  sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
                >
                  email
                </Icon>
                sinarbudidayaalam001@gmail.com</MKTypography>
              <MKTypography variant="body2">
                <div>
                  <MKTypography variant="h6">Warehouse:</MKTypography>
                </div>
                <div>
                  <Icon
                    fontSize="small"
                    sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
                  >
                    warehouse
                  </Icon>Sumatera</div>
                <div>
                  <Icon
                    fontSize="small"
                    sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
                  >
                    warehouse
                  </Icon>Jakarta</div>
                <div>
                  <Icon
                    fontSize="small"
                    sx={{ fontWeight: "normal", verticalAlign: "middle", mr: 1 }}
                  >
                    warehouse
                  </Icon>Surabaya</div>
              </MKTypography>
            </MKBox>
          </Grid>
          {routesmenus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
